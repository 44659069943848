import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { LoaderMedium } from '../../loader';

function ModalHubInfo(props) {

    return (
      <> 
        <Modal show={props.isShow||false} onHide={(e) => props.toggleModal(e, false)} size="lg" className="mod-custom team-detail portfolio-edit" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Opportunity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 col-12 col-sm-12">
                    {props.loader
                        ?
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                        :
                        <div className="ms-2 me-2">
                            <div className="row">
                                <div className="col-md-10 col-12 col-sm-12 order-1 order-md-0">
                                    <div className="d-flex">
                                        {props.data.logo && (
                                            <div className="p-0 me-3 hub-logo">
                                                <img src={props.data.logo} className="img-thumbnail" alt={`${props.data.title}`} />
                                            </div>
                                        )}
                                        <div>
                                            <h2 className="mb-1">
                                                <strong style={{fontSize: 21}}>{props.data.title}</strong>
                                                <p className="country">Expiry: {moment(props.data.expiry_at).format("Do MMM YYYY")}</p>
                                            </h2>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-2 col-12 col-sm-12 order-0 order-md-1">
                                    <div className="float-end">
                                        <a href={props.data.apply_link} target="_blank" rel="noopener noreferrer"><button className="btn btn-primary bg-color-blue p-2 pb-1 pt-1">Apply Now</button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 col-12 col-sm-12">
                                    {/* Age */}
                                    {props.data.age_details && props.data.age_details.length > 0 && (
                                    <div className="mt-2">
                                        <span className="t-light">Age:</span>
                                        <div className="same-row">
                                            {props.data.age_details.map((age_d, age_index) => (
                                                <p key={`hub_age${age_index}`} className="round-box">
                                                    {age_d.label}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    )}
                                    {/* Type */}
                                    {props.data.type_details && props.data.type_details.length > 0 && (
                                    <div className="mt-3">
                                        <span className="t-light">Opportunity Type:</span>
                                        <div className="same-row">
                                            {props.data.type_details.map((type_d, type_index) => (
                                                <p key={`hub_type${type_index}`} className="round-box">
                                                    {type_d.label}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    )}
                                    {/* Course */}
                                    {props.data.course_details && props.data.course_details.length > 0 && (
                                    <div className="mt-3">
                                        <span className="t-light">This Opportunity for:</span>
                                        <div className="same-row">
                                            {props.data.course_details.map((course_d, course_index) => (
                                                <p key={`hub_course${course_index}`} className="round-box">
                                                    {course_d.label}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12 col-sm-12">
                                    {/* Mode */}
                                    {props.data.mode_details && props.data.mode_details.length > 0 && (
                                    <div className="mt-2">
                                        <span className="t-light">Mode:</span>
                                        <div className="same-row">
                                            {props.data.mode_details.map((mode_d, mode_index) => (
                                                <p key={`hub_mode${mode_index}`} className="round-box">
                                                    {mode_d.label}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    )}
                                    {/* Benefits */}
                                    {props.data.benefit_details && props.data.benefit_details.length > 0 && (
                                    <div className="mt-3">
                                        <span className="t-light">Benefits of Applying:</span>
                                        <div className="same-row">
                                            {props.data.benefit_details.map((benefit_d, benefit_index) => (
                                                <p key={`hub_benefit${benefit_index}`} className="round-box">
                                                    {benefit_d.label}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    )}
                                    {/* Duration */}
                                    {props.data.duration && (
                                    <div className="mt-3">
                                        <span className="t-light">Duration:</span>
                                        <div>
                                            <p className="round-box">
                                                {props.data.duration}
                                            </p>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            {/* </div> */}
                            {/* <div className="row mt-3"> */}
                                <div className="col-md-12 col-12 col-sm-12">
                                {props.data.eligibility && (
                                    <div className="mt-3">
                                        <span className="t-light">Eligibility:</span>
                                        <p>{props.data.eligibility}</p>
                                    </div>
                                )}
                                {props.data.description && (
                                    <div className="mt-3">
                                        <span className="t-light">Description:</span>
                                        <p>{props.data.description}</p>
                                    </div>
                                )}
                                {props.data.is_reserved && props.data.is_reserved === 1 && (
                                    <div className="mt-3">
                                        <span className="t-light">Is Reserved for IFSPD:</span>
                                        <p>{props.data.is_reserved === 1 ? 'Yes' : 'No'}</p>
                                    </div>
                                )}
                                {props.data.reservation_details && (
                                    <div className="mt-3">
                                        <span className="t-light">Details of Reservation:</span>
                                        <p>{props.data.reservation_details}</p>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <button className="btn btn-secondary" onClick={(e) => props.toggleModal(e, false)}>Close</button>
                {props.data.apply_link && (
                    <a href={props.data.apply_link} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">Apply Now</button></a>
                )}
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalHubInfo;